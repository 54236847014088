/** @jsx jsx */
import styled from "@emotion/styled";
import { Box, Container, Heading, jsx, Text } from "theme-ui";

import NavBar from "./NavBar";

const HeaderContainer = styled.header`
  overflow: hidden;
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  height: 95vh;
  max-height: 640px;
  padding-top: 24px;
  position: relative;

  @media (min-width: 580px) {
    max-height: 700px;
  }

  @media (min-width: 992px) {
    max-height: 800px;
  }

  @media (min-width: 1200px) {
    height: 62vh;
    min-height: 500px;
    padding-top: 58px;
  }
`;

const HeaderMediumContainer = styled.header`
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  height: 100vh;
  max-height: 400px;
  padding-top: 24px;
  position: relative;

  @media (min-width: 1200px) {
    height: 100vh;
    max-height: 450px;
    padding-top: 58px;
  }
`;

const HeaderSmallContainer = styled.header`
  align-items: center;
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  display: flex;
  height: 100px;
  max-height: 100px;
  position: relative;

  @media (min-width: 1200px) {
    height: 100vh;
    max-height: 170px;
  }
`;

const HeaderImage = styled.img`
  display: block;
  max-width: 100%;
`;

const Header = ({
  backgroundImage,
  heroImage,
  heroImageAlt,
  heroText,
  heroTitle,
  heroVariant,
  small,
  large,
}) => {
  if (heroVariant === "large" || heroVariant === undefined) {
    return (
      <HeaderContainer
        sx={{
          backgroundImage: `${
            typeof backgroundImage !== "undefined"
              ? `url(${backgroundImage}),`
              : "none,"
          } linear-gradient(87.98deg, #256262 3.41%, #014343 96.59%)`,
        }}
      >
        <NavBar />

        <Container
          variant="containerLg"
          sx={{ height: [null, null, null, "calc(100% - 45px)"] }}
        >
          <Box
            sx={{
              alignItems: ["center", null, null, "flex-start"],
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: ["flex-start", null, null, null, "center"],
            }}
          >
            <Box
              mt={[10, null, null, null, 0]}
              sx={{
                color: "white",
                maxWidth: ["100%"],
                zIndex: 3,
                position: "relative",
              }}
            >
              <Heading
                sx={{ fontSize: [small, null, null, 14] }}
                mb={[5, null, null, 7]}
                as="h1"
                variant="headingXL"
              >
                {heroTitle}
              </Heading>
              <Text
                as="p"
                sx={{
                  fontSize: [4],
                }}
              >
                {heroText}
              </Text>
            </Box>
          </Box>
        </Container>

        <Box
          sx={{
            position: "absolute",
            bottom: -11,
            right: 5,
            zIndex: 2,
          }}
        >
          <HeaderImage
            src={heroImage}
            alt={heroImageAlt}
            sx={{ maxWidth: ["100%", null, null, null, "415px", "100%"] }}
          />
        </Box>
      </HeaderContainer>
    );
  } else if (heroVariant === "medium") {
    return (
      <HeaderMediumContainer
        sx={{
          background:
            "url(" +
            backgroundImage +
            "), linear-gradient(87.98deg, #256262 3.41%, #014343 96.59%)",
        }}
      >
        <NavBar />

        <Container
          sx={{
            height: [null, null, null, "calc(100% - 45px)"],
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: ["center", null, null, null, "center"],
            }}
          >
            <Box
              sx={{
                color: "white",
                textAlign: "center",
              }}
            >
              <Heading
                as="h1"
                mt={[10, null, null, 0]}
                variant="headingXL"
                sx={{
                  position: "relative",
                  zIndex: 2,
                }}
              >
                {heroTitle}
              </Heading>
            </Box>
          </Box>
        </Container>

        <Box
          sx={{
            bottom: 0,
            position: "absolute",
            right: 0,
          }}
        >
          <HeaderImage
            alt={heroImageAlt}
            src={heroImage}
            sx={{ width: ["300px", null, null, "500px"] }}
          />
        </Box>
      </HeaderMediumContainer>
    );
  } else if (heroVariant === "small") {
    return (
      <HeaderSmallContainer
        sx={{
          background:
            "url(" +
            backgroundImage +
            "), linear-gradient(87.98deg, #256262 3.41%, #014343 96.59%)",
        }}
      >
        <NavBar />
      </HeaderSmallContainer>
    );
  }
};

export default Header;
