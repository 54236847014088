/** @jsx jsx */
import React, { useState, useRef, useEffect } from "react"; //eslint-disable-line no-unused-vars
import { jsx, Box, Flex } from "theme-ui";
import { Link } from "gatsby";
import { navigate } from "@reach/router";

const NavDropdown = ({ dropdownLinks, linkText, linkUrl, setNavStatus }) => {
  const [dropDownActive, setDropdownActive] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState("0px");
  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    if (!hovering) {
      closeDropdown();
    } else {
      openDropdown();
    }
  }, [hovering]);

  const dropdownList = useRef(null);
  const dropDownButton = useRef(null);

  const handleNavigationClick = target => {
    setNavStatus(false);
    navigate(target);
  };

  function toggleDropdown() {
    setDropdownActive(!dropDownActive);

    setDropdownHeight(
      dropDownActive ? "0px" : `${dropdownList.current.scrollHeight}px`,
    );
  }

  function openDropdown() {
    let matchMedia = window.matchMedia("(min-width: 992px)");

    if (matchMedia.matches) {
      setDropdownActive(true);
      setDropdownHeight(dropdownList.current.scrollHeight + "px");
    }
  }

  function closeDropdown() {
    let matchMedia = window.matchMedia("(min-width: 992px)");

    if (matchMedia.matches) {
      setDropdownActive(false);
      setDropdownHeight(0 + "px");
    }
  }

  const activeDropdownButtonStyles = dropDownActive
    ? {
        transform: "rotate(45deg)",
      }
    : {};

  const activeDropDownStyles = dropDownActive
    ? {
        background: [null, null, null, "#407979"],
        borderRadius: [null, null, null, 5],
        maxHeight: dropdownHeight,
        opacity: 1,
        top: "100%",
        p: [null, null, null, 5],
        width: [null, null, null, "fit-content"],
        li: {
          "&.dropdown__list-item:not(:first-of-type)": {
            ml: [null, null, null, 0],
            mt: [10, null, null, 4],
          },
        },
      }
    : {};

  return (
    <span
      sx={{ py: 6, display: "block" }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      role={"none"}
    >
      <Flex sx={{ alignItems: "center" }}>
        <Link
          to={linkUrl}
          sx={{
            borderColor: "transparent",
            borderStyle: "solid",
            borderWidth: "0 0 2px 0",
            color: "white",
            fontSize: 2,
            position: "relative",
            px: 1,
            textDecoration: "none",
            textTransform: "uppercase",
            "&:hover": {
              borderColor: [null, null, null, "#A8D5D6"],
            },
          }}
        >
          {linkText}
        </Link>

        <button
          onClick={toggleDropdown}
          ref={dropDownButton}
          type="button"
          sx={{
            backgroundColor: "transparent",
            border: "none",
            borderRadius: 0,
            cursor: "pointer",
            display: [null, null, null, "none"],
            height: 12,
            ml: 9,
            outline: "none",
            p: 0,
            position: "relative",
            transform: "rotate(-135deg)",
            transition: "all 0.3s",
            width: 12,
            ".arrow": {
              borderLeft: "2px solid white",
              borderTop: "2px solid white",
              display: "block",
              height: 12,
              left: 0,
              position: "absolute",
              top: 0,
              width: 12,
            },
            ...activeDropdownButtonStyles,
          }}
        >
          <span
            sx={{
              height: 1,
              left: -10000,
              overflow: "hidden",
              position: "absolute",
              top: "auto",
              width: 1,
            }}
          >
            {dropDownActive
              ? "close dropdown navigation"
              : "open dropdown navigation"}
          </span>
          <span className="arrow"></span>
        </button>
      </Flex>

      <Box as="nav">
        <ul
          ref={dropdownList}
          className="dropdown-list"
          sx={{
            listStyleType: "none",
            maxHeight: 0,
            mt: [0],
            opacity: 0,
            overflow: "hidden",
            position: [null, null, null, "absolute"],
            top: [null, null, null, "100%"],
            transition: "max-height 0.3s, opacity 0.3s ",
            width: [null, null, null, 0],
            zIndex: 10,
            ...activeDropDownStyles,
          }}
        >
          {dropdownLinks.map((link, index) => (
            <li key={index} className="dropdown__list-item">
              <a
                href={link.url}
                onClick={() => handleNavigationClick(link.url)}
                // to={link.url}
                sx={{
                  color: "white",
                  fontSize: 1,
                  textDecoration: "none",
                }}
              >
                {link.name}
              </a>
            </li>
          ))}
        </ul>
      </Box>
    </span>
  );
};

export default NavDropdown;
