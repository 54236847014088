/** @jsx jsx */
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { jsx, Box } from "theme-ui";

const StyledLink = styled(Link)`
  display: inline-block;
  text-align: center;
  text-decoration: none;
`;
const PlainLink = styled.a`
  display: inline-block;
  text-align: center;
  text-decoration: none;
`;

const Button = ({
  border,
  buttonPos,
  className,
  buttonText,
  fontSize = ["12px", null, "20px"],
  fontWeight = "400",
  lineHeight = ["17px", null, "33px"],
  linkTo,
  m,
  mb,
  ml,
  mr,
  mt,
  mx,
  my,
  p,
  pb,
  pl,
  pr,
  pt,
  px = 7,
  py = 4,
  textTransform,
  variant = "primary",
  news = false,
  ...props
}) => {
  const internal = /(http(s?)):\/\//i.test(linkTo);

  return (
    <Box className={className} sx={{ textAlign: buttonPos }}>
      {internal ? (
        <PlainLink
          {...props}
          sx={{
            border,
            fontSize,
            fontWeight,
            lineHeight,
            m,
            mb,
            ml,
            mr,
            mt,
            mx,
            my,
            p,
            pb,
            pl,
            pr,
            pt,
            px,
            py,
            textTransform,
            variant: `buttons.${variant}`,
          }}
          href={linkTo}
          target={"_blank"}
          rel="noopener noreferrer"
        >
          {buttonText}
        </PlainLink>
      ) : (
        <StyledLink
          {...props}
          sx={{
            border,
            fontSize,
            fontWeight,
            lineHeight,
            m,
            mb,
            ml,
            mr,
            mt,
            mx,
            my,
            p,
            pb,
            pl,
            pr,
            pt,
            px,
            py,
            textTransform,
            variant: `buttons.${variant}`,
          }}
          to={`${news ? `/news/` : ""}${linkTo}`}
        >
          {buttonText}
        </StyledLink>
      )}
    </Box>
  );
};
export default Button;
