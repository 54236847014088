/** @jsx jsx */
import React, { useState, useRef } from "react";
import { Container, jsx, Box, Flex } from "theme-ui";
import { Link } from "gatsby";

import LinkButton from "./LinkButton";
import NavDropdown from "./NavDropdown";

const NAV_LINKS = [
  {
    name: "platform",
    url: "/platform",
    dropdownLinks: [
      {
        name: "Strategic Anemia Advisor",
        url: "/platform#strategic-anemia",
      },
      {
        name: "Future Modules",
        url: "/platform#future-modules",
      },
    ],
  },
  {
    name: "about",
    url: "/about-us",
  },
  {
    name: "news & insights",
    url: "/news",
  },
];

const NavBar = ({ className }) => {
  const [navActive, setNavActive] = useState(false);
  const [navButtonTopPos, setNavButtonTopPos] = useState("0px");
  const [navButtonLeftPos, setNavButtonLeftPos] = useState("0px");

  const mobileNavButton = useRef(null);
  const navContainer = useRef(null);

  function toggleNav() {
    setNavActive(navActive === 0 ? 1 : 0);

    const { topPos, leftPos } = getElementCords(mobileNavButton.current);

    setNavButtonTopPos(topPos);
    setNavButtonLeftPos(leftPos);

    let matchMedia = window.matchMedia("(max-width: 992px)");

    if (matchMedia && !navActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    window.addEventListener("resize", e => {
      let matchDesktop = window.matchMedia("(min-width: 992px)");

      setNavActive(0);

      if (matchDesktop) {
        document.body.style.overflow = "auto";
      }
    });
  }

  function getElementCords(elem) {
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    let topPos = Math.round(top);
    let leftPos = Math.round(left);

    return { topPos, leftPos };
  }

  const activeHamburgerStyles = navActive
    ? {
        position: "fixed",
        top: navButtonTopPos + "px",
        left: navButtonLeftPos + "px",
        ".bar-container span:first-of-type, .bar-container span:last-of-type": {
          opacity: 0,
          width: 0,
        },
        ".bar-container span:nth-of-type(2)::after": {
          transform: "rotate(-90deg)",
          width: "20px",
        },
        ".bar-container span:nth-of-type(2)": {
          transform: "rotate(45deg)",
          width: "20px",
        },
      }
    : {};

  const activeRequestDemoLinkStyles = navActive
    ? {
        display: "block",
        maxWidth: ["100%", null, 720, 960, 1140, 1440],
        mx: "auto",
        px: 5,
      }
    : {};

  const activeMobileNavStyles = navActive
    ? {
        background:
          "linear-gradient(106.64deg, #407979 3.49%, #004242 62.54%);",
        height: "100vh",
        left: 0,
        opacity: 1,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 10,
        pt: navContainer.current.scrollHeight + 40 + "px",
        ul: {
          "&.nav-list": {
            alignItems: "flex-start",
            flexDirection: "column",
            height: "auto",
            maxWidth: ["100%", null, 720, 960, 1140, 1440],
            mx: "auto",
            overflow: "visible",
            px: 5,
          },
        },
        li: {
          "&:not(:first-of-type)": {
            mt: 13,
          },
        },
      }
    : {};

  return (
    <Container className={className} variant="containerLg" sx={{position: 'relative', zIndex: 4}}>
      <Box
        as="nav"
        ref={navContainer}
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
          pt: [3, null, null, 0],
        }}
      >
        <Box>
          <Link
            to="/"
            sx={{
              color: "white",
              fontSize: "26px",
              fontWeight: "bold",
              textDecoration: "none",
              textTransform: "uppercase",
            }}
          >
            Dosis
          </Link>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              opacity: [0, null, null, 1],
              transition: "opacity 0.3s",
              ...activeMobileNavStyles,
            }}
          >
            <Flex
              as="ul"
              className="nav-list"
              sx={{
                alignItems: "center",
                height: [0, null, null, "auto"],
                listStyleType: "none",
                m: 0,
                my: -6,
                overflow: ["hidden", null, null, "visible"],
                p: 0,
                position: "relative",
                li: {
                  "&:not(:first-of-type)": {
                    ml: [null, null, null, 13],
                  },
                },
              }}
            >
              {NAV_LINKS.map((link, index) => (
                <React.Fragment key={index}>
                  {link.dropdownLinks ? (
                    <li>
                      <NavDropdown
                        setNavStatus={setNavActive}
                        dropdownLinks={link.dropdownLinks}
                        linkText={link.name}
                        linkUrl={link.url}
                      />
                    </li>
                  ) : (
                    <li>
                      <Link
                        to={link.url}
                        sx={{
                          borderColor: "transparent",
                          borderStyle: "solid",
                          borderWidth: "0 0 2px 0",
                          color: "white",
                          fontSize: 2,
                          px: 1,
                          textDecoration: "none",
                          textTransform: "uppercase",
                          "&:hover": {
                            borderColor: [null, null, null, "#A8D5D6"],
                          },
                        }}
                      >
                        {link.name}
                      </Link>
                    </li>
                  )}
                </React.Fragment>
              ))}
            </Flex>

            <LinkButton
              buttonText="REQUEST A DEMO"
              fontSize={["12px", null, null, "16px"]}
              linkTo="/request-a-demo"
              px={5}
              py={1}
              sx={{
                minWidth: "auto",
                display: "none",
                mr: [10, null, null, 0],
                ml: [5],
                mt: 13,
                ...activeRequestDemoLinkStyles,
              }}
            />
          </Box>

          <LinkButton
            buttonText="REQUEST A DEMO"
            fontSize={["12px", null, null, "16px"]}
            linkTo="/request-a-demo"
            px={5}
            py={1}
            sx={{
              minWidth: "auto",
              mr: [10, null, null, 0],
              ml: [13],
            }}
          />

          <button
            onClick={toggleNav}
            ref={mobileNavButton}
            type="button"
            sx={{
              backgroundColor: "transparent",
              border: "none",
              borderRadius: 0,
              cursor: "pointer",
              display: [null, null, null, "none"],
              height: 20,
              justifySelf: "flex-end",
              m: 0,
              outline: "none",
              p: 0,
              position: "relative",
              width: 20,
              zIndex: 12,
              ".bar-container": {
                display: "flex",
                flexDirection: "column",
                height: 12,
                justifyContent: "space-between",
                width: 20,
                span: {
                  backgroundColor: "white",
                  display: "block",
                  height: 2,
                  position: "relative",
                  transition: "all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1)",
                  width: 20,
                  zIndex: 12,
                },
              },
              ".bar-container span:nth-of-type(2):after": {
                backgroundColor: "white",
                content: '""',
                height: "100%",
                left: 0,
                position: "absolute",
                top: 0,
                width: 20,
              },
              ...activeHamburgerStyles,
            }}
          >
            <span
              sx={{
                height: 1,
                left: -10000,
                overflow: "hidden",
                position: "absolute",
                top: "auto",
                width: 1,
              }}
            >
              {navActive ? "close mobile navigation" : "open mobile navigation"}
            </span>
            <span className="bar-container">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </Box>
      </Box>
    </Container>
  );
};

export default NavBar;
