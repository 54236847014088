/** @jsx jsx */
import styled from "@emotion/styled";
import { Box, Container, Flex, jsx, Heading } from "theme-ui";
import { Link } from "gatsby";

import FacebookLogo from "../icons/facebook-logo.svg";
import FooterGraphic from "../../static/images/dots-opacity.svg";
import LinkedInLogo from "../icons/linkedin-logo.svg";
import TwitterLogo from "../icons/twitter-logo.svg";

const NAV_LINKS = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Dosing Platform",
    url: "/platform",
  },
  {
    name: "About Us",
    url: "/about-us",
  },
  {
    name: "Request Demo",
    url: "/request-a-demo",
  },
  {
    name: "Privacy Policy",
    url: "/media/dosis-online-privacy-policy.pdf",
  },
];

const FooterContainer = styled.footer`
  background-color: #122629;
  background-position: bottom center;
  background-repeat: no-repeat;
  color: #fff;
  padding: 68px 0 35px;
  position: relative;

  @media (min-width: 992px) {
    background-position: bottom right;
  }
`;

const NavList = styled.ul`
  list-style-type: none;
  margin: 15px 0 0;
  padding: 0;

  li:not(:first-of-type) {
    margin-top: 8px;
  }

  @media (min-width: 768px) {
    margin-top: 30px;
  }
`;

const NavLink = styled(Link)`
  border-bottom: 1px solid transparent;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.2s;

  &:hover {
    border-color: #fff;
  }
`;

const FooterLink = styled.a`
  border-bottom: 1px solid transparent;
  color: #fff;
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    border-color: #fff;
  }
`;

const SocialMediaLink = styled.a`
  svg {
    height: 30px;
    transition: all 0.3s;
    width: auto;
  }

  &:hover {
    svg {
      path {
        fill: #51bfa0;
        transition: all 0.3s;
      }
    }
  }
`;

const SocialMediaList = styled.ul`
  align-items: center;
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0 100px 0 0;

  li {
    &:not(:first-of-type) {
      margin-left: 30px;
    }
  }
`;

const Footer = () => {

  return (
    <FooterContainer sx={{ backgroundImage: `url('${FooterGraphic}')` }}>
      <Container>
        <Flex sx={{ justifyContent: "space-between", flexWrap: "wrap" }}>
          <Box as="nav" sx={{ flex: ["0 0 100%", null, "0 0 50%", "0 0 25%"] }}>
            <Heading
              as="h3"
              sx={{
                fontSize: [4, null, null, 6],
                fontWeight: "500",
                lineHeight: "30px",
                m: 0,
              }}
            >
              Dosis
            </Heading>
            <NavList>
              {NAV_LINKS.map((link, index) => (
                <li key={index}>
                  <NavLink to={link.url}>{link.name}</NavLink>
                </li>
              ))}
            </NavList>
          </Box>

          <Box
            as="nav"
            mt={[7, null, 0]}
            sx={{ flex: ["0 0 100%", null, "0 0 50%", "0 0 25%"] }}
          >
          </Box>

          <Box
            as="nav"
            mt={[7, null, null, 0]}
            sx={{ flex: ["0 0 100%", null, null, "0 0 25%"] }}
          >
            <Heading
              as="h3"
              sx={{
                fontSize: [4, null, null, 6],
                fontWeight: "500",
                lineHeight: "30px",
                m: 0,
              }}
            >
              Contact
            </Heading>
            <NavList>
              <li>
                <FooterLink href="mailto:hello@dosisinc.com">
                  hello@dosisinc.com
                </FooterLink>
              </li>
              <li>
                <FooterLink href="tel:650-383-0186">(650) 383-0186</FooterLink>
              </li>
            </NavList>
          </Box>
        </Flex>

        <Flex
          mt={7}
          sx={{
            alignItems: [null, null, null, "center"],
            flexDirection: ["column", null, null, "row"],
            justifyContent: ["space-between"],
            flexWrap: "wrap",
          }}
        >
          <Heading
            sx={{
              color: "white",
              fontSize: [8, null, null, 8],
              fontWeight: "bold",
              mt: [5, null, null, 0],
              order: ["1", null, null, "0"],
              textTransform: "uppercase",
            }}
          >
            Dosis
          </Heading>

          <SocialMediaList>
            <li>
              <SocialMediaLink
                href="https://twitter.com/dosisinc"
                target="_blank"
                rel="noopener referrer"
              >
                <TwitterLogo />
              </SocialMediaLink>
            </li>
            <li>
              <SocialMediaLink
                href="https://www.facebook.com/dosisinc"
                target="_blank"
                rel="noopener referrer"
              >
                <FacebookLogo />
              </SocialMediaLink>
            </li>
            <li>
              <SocialMediaLink
                href="http://www.linkedin.com/company/dosis-inc"
                target="_blank"
                rel="noopener referrer"
              >
                <LinkedInLogo />
              </SocialMediaLink>
            </li>
          </SocialMediaList>
        </Flex>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
